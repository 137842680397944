export const navitem = [
  {
    title: "Where To Go",
    path: "/city",
    cname: "nav-text",
  },
  {
    title: "What To Do",
    path: "/adventure",
    cname: "nav-text",
  },
  {
    title: "Trip",
    path: "/trip",
    cname: "nav-text",
  },
  {
    title: "Visit Planner",
    path: "/visitplanner",
    cname: "nav-text",
  },

];
