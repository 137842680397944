import React from "react";
import './logo.css';
const Logo = () => {
  return (
      <span>
        <span className="e font-face-rh ">Egypt</span>
        <br/>
        <span className="tg font-face-ab">TOUR GUIDE</span>
      </span>

  );
};

export default Logo;
