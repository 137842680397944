import React, { Component } from "react";
import getnotifications from "../mostuse/getnotifications";
import Loading from "../mostuse/loading";
import "./Notifications.css";
class Notificationshow extends Component {
  state = { notifications: [], load: true };
  async componentDidMount() {
    const notifications = await getnotifications();
    this.setState({ notifications, load: false });
  }
  time = (e) => {
    var time = "";
    const date_future = new Date(e).getTime();
    const date_now = new Date().getTime();
    var delta = Math.abs(date_future - date_now) / 1000;
    // calculate (and subtract) whole days
    var days = Math.floor(delta / 86400);
    delta -= days * 86400;

    // calculate (and subtract) whole hours
    var hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    // calculate (and subtract) whole minutes
    var minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    if (days === 0 && hours > 0) {
      time = `${hours}h`;
    } else if (days === 0 && hours === 0 && minutes > 0) {
      time = minutes + "min";
    } else if (days > 0 && days < 30) {
      time = days + "d";
    } else if (days >= 30) {
      time = days / 30 + "m";
    } else {
      time = "NOW";
    }
    return time;
  };
  render() {
    if (this.state.load&&this.state.notifications.length===0) {
      return <Loading></Loading>;
    } else
      return (
        <React.Fragment>
          <div className="container">
            {this.state.notifications.map((ele, ind) => {
              return (
                <span key={ind}>
                  <div className="row align-items-center">
                    <div className="d-flex r-notifi justify-content-start align-items-center">
                      <div className="notific-icon me-3">

 {/* Logo svg */}
 <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="50px" height="50px" viewBox="0 0 6350.000000 6350.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,6350.000000) scale(0.100000,-0.090000)"fill="#F4F9F9">
<path d="M38725 58809 c-1171 -59 -2851 -225 -5260 -520 -5155 -629 -13892
-1910 -24250 -3554 -1191 -189 -1803 -287 -4065 -650 -303 -49 -559 -95 -591
-106 -117 -43 -297 -164 -348 -234 -43 -60 -46 -84 -21 -160 17 -52 35 -81 82
-131 105 -112 245 -163 447 -164 85 0 102 -2 92 -13 -8 -11 24 -8 132 10 241
41 2510 415 3287 542 2784 455 5366 867 7370 1176 377 58 698 108 714 111 l28
6 -28 -48 c-60 -104 -877 -1466 -1184 -1974 -2123 -3513 -4516 -7409 -6566
-10689 l-622 -995 -3152 -577 c-2014 -369 -3170 -585 -3202 -598 -134 -56
-304 -209 -358 -322 -41 -88 -40 -109 14 -156 131 -115 360 -202 534 -203 53
0 819 136 2849 505 1527 278 2778 503 2780 501 5 -5 -1038 -1627 -1810 -2816
-1581 -2433 -3089 -4670 -3616 -5364 -151 -198 -336 -458 -488 -686 -636 -953
-989 -1748 -1084 -2440 -20 -142 -17 -444 4 -555 28 -142 64 -248 122 -363
199 -391 600 -685 1151 -845 377 -110 725 -151 1269 -151 384 0 624 14 1035
60 2638 291 6696 1599 12700 4092 1266 526 2622 1107 2673 1146 109 83 140
231 76 363 -66 136 -214 194 -493 194 -224 -1 -256 -9 -631 -169 -6948 -2953
-12098 -4652 -14955 -4933 -274 -27 -877 -33 -1040 -11 -328 46 -508 118 -651
261 -428 431 -213 1407 611 2765 205 338 399 630 720 1081 927 1303 2260 3321
4950 7490 386 600 708 1095 714 1102 6 6 3314 631 7351 1388 5986 1122 7350
1380 7395 1401 158 74 332 251 355 362 6 29 3 38 -22 64 -97 103 -349 220
-533 248 l-75 11 -6965 -1295 c-3831 -712 -6966 -1293 -6968 -1291 -2 1 261
427 584 946 2833 4553 6016 9717 6994 11349 130 217 306 508 390 645 84 138
163 273 176 300 27 59 44 173 44 290 l0 83 38 6 c448 71 4163 618 6017 886
9653 1394 15486 2074 16185 1885 71 -19 210 -19 284 0 179 47 337 186 366 323
40 182 -159 359 -458 409 -115 19 -815 27 -1097 12z"/>
<path d="M57450 53491 c-148 -32 -288 -99 -361 -175 -37 -38 -154 -286 -377
-796 -50 -113 -122 -275 -162 -360 -573 -1236 -1450 -3192 -2247 -5015 -135
-308 -246 -561 -247 -562 -2 -2 -2849 -561 -6327 -1243 -5366 -1053 -6333
-1245 -6386 -1270 -175 -81 -303 -215 -303 -316 0 -20 10 -64 22 -98 42 -114
167 -208 323 -241 168 -35 -279 -119 6248 1165 3301 649 6004 1179 6008 1178
5 -2 -449 -1033 -1007 -2293 -1077 -2430 -1349 -3064 -2081 -4865 -1990 -4894
-3130 -8237 -3362 -9855 -61 -427 -51 -777 27 -910 37 -62 136 -176 182 -210
153 -112 370 -160 625 -136 830 78 1766 674 3106 1977 1090 1060 2366 2491
4669 5239 840 1002 1330 1570 1755 2034 209 230 437 467 522 546 40 36 75 74
78 85 3 10 -1 35 -9 55 -18 41 -126 157 -198 213 -27 20 -43 39 -37 42 18 12
-119 50 -222 62 -164 19 -368 -17 -477 -85 -20 -13 -42 -22 -47 -20 -12 4
-351 -335 -528 -529 -400 -438 -943 -1075 -1567 -1838 -738 -904 -1760 -2102
-2549 -2990 -1840 -2070 -3229 -3389 -4110 -3900 -95 -55 -306 -160 -322 -160
-9 0 -10 31 -5 128 63 1213 1289 4786 3423 9977 870 2115 1889 4489 2943 6855
117 261 248 557 293 658 44 100 84 182 87 182 4 0 1767 342 3919 760 3814 740
3913 760 3980 795 174 91 321 265 325 384 1 45 0 46 -69 98 -129 98 -330 183
-483 205 l-77 11 -3589 -717 c-1974 -394 -3592 -716 -3596 -714 -5 2 26 80 67
173 41 94 138 314 215 490 980 2233 1784 3977 2410 5229 154 308 160 322 155
367 -15 125 -165 312 -309 385 -41 21 -61 24 -145 23 -54 0 -136 -8 -183 -18z"/>
<path d="M44915 43195 c-133 -29 -289 -113 -401 -217 -25 -24 -250 -529 -1160
-2609 -621 -1418 -1133 -2579 -1139 -2579 -19 0 -406 -176 -738 -336 -857
-413 -2461 -1262 -4251 -2253 -220 -121 -400 -220 -402 -218 -1 1 25 52 58
112 583 1078 1269 2397 1773 3405 382 764 386 773 386 854 0 84 -25 141 -90
206 -103 103 -255 148 -456 136 -161 -9 -282 -50 -366 -125 -24 -21 -89 -103
-144 -182 -642 -922 -1474 -1837 -2366 -2604 -1324 -1138 -2783 -1961 -3814
-2151 -58 -11 -167 -18 -307 -21 l-217 -5 -10 39 c-83 308 18 779 291 1363
102 217 282 527 477 823 l127 192 145 54 c168 62 209 84 266 143 96 98 114
221 52 344 -57 114 -120 164 -250 200 -55 16 -106 18 -354 18 -318 0 -360 -6
-474 -64 -50 -25 -130 -97 -157 -140 -5 -9 -160 -76 -344 -149 -1264 -504
-2616 -1151 -3878 -1857 -112 -63 -206 -114 -207 -112 -2 2 15 48 37 103 272
677 653 1694 945 2525 364 1036 787 2373 882 2790 12 52 26 109 31 126 14 44
12 101 -4 132 -26 51 -115 123 -199 162 -108 51 -182 70 -297 76 -169 11 -305
-39 -415 -150 -68 -68 -60 -52 -236 -451 -114 -260 -420 -884 -455 -930 -13
-17 -16 -18 -24 -5 -84 148 -326 406 -475 507 -304 205 -640 296 -1095 296
-274 1 -495 -28 -775 -100 -1104 -283 -2100 -1063 -2700 -2114 -183 -320 -396
-787 -503 -1101 -112 -327 -161 -561 -169 -808 -11 -321 54 -568 201 -775 162
-226 471 -403 845 -482 114 -24 147 -27 346 -27 239 0 340 12 596 75 818 199
1794 770 2647 1549 l123 112 43 -16 c89 -34 89 -13 -9 -282 -209 -571 -582
-1526 -747 -1913 l-54 -125 -229 -139 c-4280 -2587 -8280 -6044 -9967 -8612
-328 -499 -577 -997 -683 -1366 -66 -227 -80 -323 -79 -534 0 -164 3 -201 22
-270 50 -182 138 -314 286 -431 181 -143 402 -227 706 -265 123 -16 462 -16
615 0 463 48 985 175 1460 353 1507 568 3013 1756 4447 3508 1037 1268 2030
2805 3013 4665 377 714 1035 2079 1310 2716 l28 66 206 116 c1020 573 2195
1166 3266 1648 288 129 725 319 725 314 0 -1 -38 -78 -85 -170 -385 -763 -479
-1352 -280 -1760 66 -136 116 -196 235 -286 361 -272 846 -369 1422 -284 1138
168 2666 950 4082 2088 l168 136 59 -42 c33 -23 58 -46 55 -52 -2 -5 -256
-462 -564 -1013 l-560 -1004 -464 -274 c-3395 -2013 -6480 -4090 -8658 -5829
-2237 -1786 -3537 -3212 -3884 -4258 -64 -195 -81 -299 -81 -502 0 -213 9
-255 90 -421 177 -365 548 -641 1110 -828 370 -123 656 -166 1113 -166 272 0
412 11 647 50 1190 199 2503 961 3942 2287 206 189 737 718 967 963 1087 1154
2177 2533 3366 4260 265 386 867 1289 1087 1630 379 590 1005 1617 1478 2425
87 149 164 278 172 287 16 18 66 47 888 513 1466 831 2670 1492 3680 2020 403
210 1135 577 1145 573 9 -3 -3685 -8446 -3904 -8923 -461 -1001 -927 -2067
-1228 -2805 -107 -261 -103 -250 -88 -305 21 -75 95 -147 204 -200 111 -54
195 -75 301 -75 105 0 202 22 292 64 75 36 158 108 186 162 8 16 420 947 915
2069 495 1122 973 2195 1063 2385 211 445 853 1730 1099 2200 559 1065 1211
2238 1740 3130 90 151 181 306 203 343 l40 69 66 -129 c138 -267 260 -442 430
-616 516 -529 1201 -634 2026 -310 941 371 1961 1282 2843 2540 557 796 1011
1711 1185 2388 32 125 33 134 25 240 -40 518 -292 1003 -698 1340 -216 179
-478 320 -709 381 -82 22 -124 27 -291 31 -301 9 -521 -24 -814 -121 -845
-279 -1768 -1030 -2819 -2291 -82 -99 -153 -181 -156 -182 -3 -2 -37 3 -76 10
-59 11 -70 17 -69 33 1 16 1865 4262 1997 4549 67 146 55 225 -49 327 -125
123 -367 185 -559 143z m2724 -3201 c205 -47 352 -205 419 -453 25 -91 26
-108 26 -346 1 -277 -9 -355 -79 -620 -275 -1050 -1161 -2353 -2274 -3343
-578 -515 -1182 -860 -1529 -874 -49 -1 -58 2 -151 65 -784 531 -769 1464 44
2692 436 658 1112 1395 1835 1998 622 518 1219 877 1462 877 28 0 38 4 38 16
0 14 9 16 73 10 39 -4 101 -14 136 -22z m-21874 -43 c3 -5 29 -17 58 -27 485
-159 645 -730 406 -1458 -21 -65 -65 -179 -96 -253 -53 -124 -64 -142 -147
-236 -124 -141 -456 -476 -608 -614 -741 -671 -1585 -1184 -2221 -1348 -73
-19 -163 -44 -202 -55 -52 -16 -91 -20 -152 -18 -80 3 -83 4 -121 42 -52 50
-85 113 -114 211 -20 70 -23 102 -22 250 0 182 12 268 64 476 113 448 444
1144 751 1579 489 693 1201 1210 1923 1395 206 53 464 83 481 56z m-1047
-6736 c28 -20 52 -42 52 -50 0 -33 -571 -1179 -827 -1660 -701 -1316 -1408
-2464 -2123 -3450 -1508 -2077 -3058 -3435 -4610 -4040 -205 -80 -327 -119
-540 -174 -387 -100 -727 -156 -905 -149 -109 4 -123 12 -157 97 -20 49 -23
73 -22 201 0 163 16 245 85 447 381 1107 1905 3004 3899 4854 1282 1189 2710
2333 4225 3384 318 221 847 575 859 575 6 0 35 -16 64 -35z m9336 -876 l57
-41 -20 -31 c-958 -1587 -2065 -3228 -3047 -4519 -2364 -3108 -4445 -4909
-6076 -5257 -183 -39 -293 -52 -489 -58 -292 -8 -521 22 -766 102 -416 137
-592 339 -593 681 -1 811 1114 2139 3295 3925 1667 1364 3975 2987 6575 4622
385 243 992 617 1000 617 4 0 32 -18 64 -41z"/>
<path d="M9285 19964 c-316 -33 -537 -94 -790 -218 -427 -210 -823 -605 -1040
-1037 -147 -291 -218 -579 -232 -934 -14 -369 53 -697 206 -1010 115 -234 241
-407 441 -605 451 -448 1009 -680 1635 -680 624 0 1174 233 1616 687 392 401
602 857 650 1408 33 390 -32 767 -190 1100 -106 222 -218 380 -406 574 -376
387 -823 620 -1335 696 -110 16 -465 28 -555 19z m545 -443 c400 -70 744 -258
1035 -563 259 -271 411 -573 477 -948 21 -118 17 -480 -6 -605 -49 -269 -150
-503 -311 -727 -82 -114 -328 -358 -445 -440 -303 -214 -614 -319 -990 -335
-556 -23 -1034 172 -1425 578 -335 349 -504 765 -505 1241 -1 455 152 859 453
1198 315 356 703 562 1167 619 121 15 418 6 550 -18z"/>
<path d="M36350 19964 c-19 -2 -78 -9 -130 -15 -410 -47 -811 -214 -1135 -474
-101 -81 -276 -256 -360 -360 -244 -302 -389 -639 -452 -1045 -25 -163 -25
-523 0 -675 76 -461 257 -818 587 -1159 463 -477 999 -720 1645 -743 392 -14
756 66 1107 242 371 186 686 460 900 780 173 260 316 661 344 966 l7 69 -1477
0 -1476 0 0 -190 0 -190 1216 0 1215 0 -7 -22 c-15 -51 -93 -218 -131 -283
-262 -448 -755 -812 -1251 -926 -114 -26 -360 -36 -513 -20 -463 48 -869 223
-1182 511 -308 283 -478 570 -559 940 -19 92 -22 134 -23 335 0 182 4 251 19
330 68 369 236 683 511 958 334 333 695 510 1140 558 134 14 401 6 535 -16
486 -80 871 -317 1176 -722 l55 -73 240 0 c131 0 239 3 239 6 0 4 -31 59 -69
123 -387 653 -864 979 -1571 1076 -109 15 -523 28 -600 19z"/>
<path d="M15013 18343 c4 -1700 0 -1594 57 -1800 120 -439 488 -832 912 -974
136 -46 266 -68 433 -75 428 -18 780 115 1079 405 277 270 414 561 446 948 6
70 10 720 10 1591 l0 1472 -220 0 -220 0 0 -1519 c0 -1291 -2 -1531 -15 -1603
-40 -233 -134 -415 -296 -577 -94 -94 -174 -150 -291 -206 -118 -56 -232 -84
-373 -92 -305 -17 -565 83 -767 295 -151 158 -242 328 -301 562 -21 85 -21 90
-24 1613 l-4 1527 -215 0 -215 0 4 -1567z"/>
<path d="M41863 18343 c4 -1700 0 -1594 57 -1800 120 -439 488 -832 912 -974
136 -46 266 -68 433 -75 428 -18 780 115 1079 405 277 270 414 561 446 948 6
70 10 720 10 1591 l0 1472 -220 0 -220 0 0 -1519 c0 -1291 -2 -1531 -15 -1603
-40 -233 -134 -415 -296 -577 -94 -94 -174 -150 -291 -206 -118 -56 -232 -84
-373 -92 -305 -17 -565 83 -767 295 -151 158 -242 328 -301 562 -21 85 -21 90
-24 1613 l-4 1527 -215 0 -215 0 4 -1567z"/>
<path d="M48070 17740 l0 -2170 215 0 215 0 0 2170 0 2170 -215 0 -215 0 0
-2170z"/>
<path d="M2430 19695 l0 -205 490 0 490 0 0 -1960 0 -1960 215 0 215 0 0 1960
0 1960 495 0 495 0 0 205 0 205 -1200 0 -1200 0 0 -205z"/>
<path d="M21230 17735 l0 -2165 225 0 225 0 0 1961 0 1960 583 -4 c645 -4 651
-5 848 -76 168 -62 357 -206 452 -345 212 -313 220 -766 17 -1061 -127 -185
-353 -343 -585 -410 -176 -51 -231 -56 -573 -62 -178 -3 -322 -9 -320 -13 2
-4 329 -444 728 -978 l725 -971 268 -1 268 0 -19 28 c-11 15 -279 376 -596
802 -316 426 -576 778 -576 781 0 4 33 10 73 13 356 35 605 152 835 391 182
189 291 392 347 645 22 101 31 370 16 485 -68 514 -411 919 -923 1089 -264 88
-365 96 -1270 96 l-748 0 0 -2165z"/>
<path d="M51810 17735 l0 -2166 863 4 c833 4 866 5 977 25 454 86 798 265
1129 586 370 360 584 774 646 1249 20 154 20 451 0 603 -63 475 -268 876 -622
1215 -361 345 -786 544 -1333 625 -98 15 -227 18 -887 21 l-773 4 0 -2166z
m1650 1731 c377 -67 667 -203 953 -446 227 -193 409 -449 500 -705 96 -271
119 -641 57 -944 -123 -606 -622 -1149 -1214 -1321 -213 -62 -214 -63 -898
-67 l-628 -4 0 1756 0 1756 568 -4 c478 -3 582 -6 662 -21z"/>
<path d="M59581 19889 c-176 -21 -364 -88 -504 -182 -214 -143 -336 -324 -400
-591 -22 -91 -22 -91 -22 -1381 0 -1290 0 -1290 22 -1381 64 -267 186 -448
400 -591 145 -97 327 -161 518 -183 52 -6 370 -10 758 -10 l667 0 0 205 0 204
-717 3 c-704 4 -720 4 -796 26 -111 31 -171 67 -241 144 -74 82 -130 172 -159
258 -21 63 -22 80 -25 603 l-3 537 726 0 725 0 0 210 0 210 -725 0 -725 0 0
473 c0 266 5 500 10 537 21 135 152 342 264 417 57 39 145 68 251 82 48 7 325
11 746 11 l669 0 0 205 0 205 -682 -1 c-376 -1 -716 -5 -757 -10z"/>
</g>
</svg>



 {/* logo end */}
                      </div>
                      <div className="w-100">
                        <span className="d-flex align-items-center justify-content-between ">
                          <div>
                            <h5 className="text-white p-0 m-0" >{ele.title}</h5>
                          </div>
                          <div>
                            <p className="text-white p-0 m-0 time-s">
                              {this.time(ele.createdAt)}
                            </p>
                          </div>
                        </span>

                        <p className="text-white p-0 m-0"> {ele.content}</p>
                      </div>
                    </div>
                  </div>
                  <hr className="text-white my-4 hr-noti" />
                </span>
              );
            })}
          </div>
        </React.Fragment>
      );
  }
}

export default Notificationshow;
